<template>
    <template v-if="isAuthenticated">
        <div class="page">
            <div class="loader" v-show="isLoading" >
                <div id="spinningSquaresG">
                    <div id="spinningSquaresG_1" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_2" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_3" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_4" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_5" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_6" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_7" class="spinningSquaresG"></div>
                    <div id="spinningSquaresG_8" class="spinningSquaresG"></div>
                </div>
            </div>
            <HeadBar/>
            <SideBar/>
            <router-view/>
        </div>
    </template>
    <template v-else>
        <LoginPage/>
    </template>
</template>

<script>
import HeadBar from './components/HeadBar.vue'
import SideBar from './components/SideBar.vue'
import LoginPage from './components/LoginPage.vue'
import { mapState } from 'vuex';

export default {
    name: 'App',
    components: {
        HeadBar,
        SideBar,
        LoginPage,
    },
    computed: {
        isAuthenticated() { return this.$store.getters.isAuthenticated },
        ...mapState(['isLoading'])
    },
    created() {
        this.$store.dispatch('updateCSRFToken')
        document.body.className = localStorage.getItem('theme') === 'sp-theme-light' ? 'sp-theme-light' : 'sp-theme-dark'
    },
}
</script>

<style>
@import "@/assets/css/vue-select.css";
@import "@/assets/css/App.css";
</style>
