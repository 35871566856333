<template>
    <Transition name="sp-modal">
        <div
            ref="severityChange"
            tabindex="0"
            class="sp-modal-mask"
            @keydown.esc="$emit('close')"
        >
            <div class="sp-modal-wrapper">
                <div class="sp-modal-container">
                    <div class="sp-modal-header">
                        <span class="sp-modal-header-title">{{ severityChange.cve_name }}</span>
                        <span
                            tabindex="0"
                            class="sp-modal-header-closebtn material-symbols-outlined"
                            @click="$emit('close')"
                        >Close</span>
                    </div>
                    <div class="sp-modal-content">
                        <div class="sp-modal-severity">
                            <span>{{ severityChange.componentDetails?.component }} {{ severityChange.componentDetails?.version }}</span>
                            <v-select
                                v-model="miraSeverity"
                                :options="$parent.$parent.severity_list"
                                :searchable="false"
                                label="name"
                                placeholder="Select severity"
                            />
                        </div>
                        <div class="sp-modal-artifact-severity">
                            <span>{{ severityChange?.component?.image?.name }}</span>
                        </div>
                        <input
                            v-show="miraSeverity !== null"
                            v-model="miraCVSS3Vector"
                            type="text"
                            placeholder="CVSS 3.0 vector (optional)"
                        >
                        <textarea
                            v-show="miraSeverity !== null"
                            v-model="miraComment"
                            type="text"
                            placeholder="Reason to change"
                        />
                        <div class="sp-modal-submit">
                            <div>
                                <span v-if="miraSeverity !== null && miraComment == ''">
                                    Reason required
                                </span>
                            </div>
                            <input
                                :disabled="miraSeverity !== null && miraComment == ''"
                                type="submit"
                                :value="miraSeverity === null ? 'Reset to upstream' : 'Update severity'"
                                @click="updateSeverity"
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import vSelect from 'vue-select'

export default {
    name: 'SeverityChange',
    components: {
        vSelect,
    },
    data() { return {
        miraSeverity: this.$parent.$parent.severity_list?.filter((el) => {
            return el?.id == this.severityChange.component?.mira_severity?.id
        })[0] || null,
        miraComment: this.severityChange.component.mira_comment || '',
        miraCVSS3Vector: this.severityChange.component.mira_cvss3_vector || '',
    }},
    props: {
        severityChange: {
            type: Object,
            default: null,
        },
    },
    emits: ['close'],
    computed: {
        cveInfoSeverity() { return this.$store.state.cveInfo.severity || 'N/A' },
        severityStyle() {
            const severity = this.$store.state.cveInfo.severity || 'none'
            return { 'border-left-color': `var(--color-severity-${severity.toLowerCase()})` }
        },
        severityLabelClass() {
            const severity = this.$store.state.cveInfo.severity || 'none'
            return ['sp-severity-label', `sp-severity-label-${severity.toLowerCase()}` ]
        },
    },
    methods: {
        async updateSeverity() {
            const data = {
                cve_name: this.severityChange.cve_name,
                component_id: this.severityChange.componentDetails?.id,
                version: this.severityChange.componentDetails?.version,
                comment: this.miraComment,
                cvss3_vector: this.miraCVSS3Vector,
                severity_id: this.miraSeverity?.id,
                image_id: this.severityChange?.component?.image?.id
            }
            await this.$axios.post('/api/severity_change/', data)
            .then(() => {
                this.$parent.updateCVEInfo()
                this.$emit('close')
            })
            .catch(({response}) => { console.error(response) })

        },
        getCVSSSeverity(score) {
            const cvss_severities = ['Low', 'Low', 'Low', 'Low', 'Medium', 'Medium', 'Medium', 'High', 'High', 'Critical', 'Critical']
            const index = parseInt(score.split('.')[0])
            return cvss_severities[index]
        },
        severityCVSSClass(score) {
            let severity = 'none'
            if (score != 'N/A') {
                severity = this.getCVSSSeverity(score).toLowerCase()
            }
            return ['sp-severity-label', `sp-severity-label-${severity}` ]
        },
        severityMiraClass(item_id) {
            let severity = this.$store.state.cveInfo.severity || 'none'
            if (item_id == '7') { severity = 'medium' }
            return ['sp-severity-label', `sp-severity-label-${severity.toLowerCase()}` ]
        },
        severityMiraName(item_id) {
            let severity = this.$store.state.cveInfo.severity || 'N/A'
            if (item_id == '7') { severity = 'medium' }
            return severity
        },
    },
    mounted() {
        this.$refs.severityChange.focus()
    },

}
</script>

<style scoped>
.sp-modal-mask {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}
.sp-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.sp-modal-container {
    width: 400px;
/*    height: 200px;*/
    margin: 0px auto;
    border: 1px solid var(--color-border-header);
    border-radius: 3px;
    background-color: var(--color-bg);
    color: var(--color-fg);
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: 'Source Sans Pro','Helvetica Neue',Helvetica,Arial,sans-serif;
    font-size: 14px;
}
.sp-modal-header {
    height: 40px;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    font-weight: bold;
    color: var(--color-fg-header);
    background-color: var(--color-bg-header);
    border-top: 1px solid var(--color-border-header);
    border-bottom: 1px solid var(--color-border-header);
}
.sp-modal-header-closebtn {
    cursor: pointer;
}
.sp-modal-content {
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: calc(100% - 80px);
}
.sp-modal-content>input, .sp-modal-content>textarea, .sp-modal-submit>input {
    background-color: var(--vs-search-input-bg);
    color: var(--color-fg);
    font-size: var(--vs-font-size);
    padding: 0 3px 0 6px;
    border: 1px solid var(--vs-border-color) !important;
    border-radius: 5px;
    height: 32px;
}
.sp-modal-content>textarea {
    margin-top: 10px;
    padding: 5px 3px 0 6px;
    height: 76px;
}
.sp-modal-content>input::placeholder, .sp-modal-content>textarea::placeholder {
    color: var(--vs-search-input-color);
}
.sp-modal-content>input:focus-visible, .sp-modal-content>textarea:focus-visible {
    outline: unset;
}
.sp-modal-severity {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.sp-modal-severity .v-select {
    width: 140px;
}
.sp-modal-submit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}
.sp-modal-submit>div {
    color: var(--color-severity-high);

}
.sp-modal-submit>input[type="submit"] {
    width: 10em;
    align-self: end;
    cursor: pointer;
}

/* transition */

.sp-modal-enter-from {
    opacity: 0;
}
.sp-modal-leave-to {
    opacity: 0;
}
/*.sp-modal-enter-from .sp-modal-container,
.sp-modal-leave-to .sp-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}*/

</style>
