import { createStore } from 'vuex'

export default createStore({
    state: {
        authError: null,
        authMethods: [],
        userProfile: null,
        isLoading: false,
    },
    getters: {
        isAuthenticated: state => state.userProfile !== null,
    },
    mutations: {
        authError(state, error) { state.authError = error },
        authMethods(state, methods) { state.authMethods = methods },
        userProfile(state, profile) { state.userProfile = profile },
        showLoaderSpinner(state) { state.isLoading = true},
        hideLoaderSpinner(state) { state.isLoading = false},
    },
    actions: {
        async updateCSRFToken() {
            await this.$axios.get('/auth/csrf/')
            .then((response) => {
                this.$axios.defaults.headers.post['X-CSRFToken'] = response.headers['http_x_csrftoken']
                // commit('csrf', response.headers['http_x_csrftoken'])
            })
            .catch(({response}) => { console.error(response) })
        },
        async authMethods({commit}) {
            await this.$axios.get('/auth/methods/')
            .then((response) => commit('authMethods', response.data.auth_methods))
            .catch(({response}) => { console.error(response) })
        },
        async login({commit, dispatch}, {username, password}) {
            await this.$axios.post('/auth/login/', {username, password})
            .then(() => {
                dispatch('getUserProfile').then(dispatch('updateCSRFToken'))
            })
            .catch(({response}) => { commit('AuthError', response.data.detail) })
        },
        async logout({commit}) {
            await this.$axios.post('/auth/logout/')
            .then(() => { commit('userProfile', null) })
            .catch(({response}) => { console.error(response) })
        },
        async getUserProfile({commit}) {
            await this.$axios.get('/auth/profile/')
            .then((response) => { commit('userProfile', response.data.detail) })
            // .catch(({response}) => { console.error(response) })
        },
        toggleLoaderSpinner ( {commit}, isLoading) { 
            if (isLoading) {
                commit('showLoaderSpinner')
            } else {
                commit('hideLoaderSpinner')
            }
        }
    },
})
